import React from 'react';
import styled from 'styled-components';

import Container from '../layout/Container';
import SpeakersListAndTitle from '../components/SpeakersListAndTitle';
import { dayMeta } from '../constants/dayMeta';
import PropTypes from 'prop-types';

const ExpertsBackground = styled.div`
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const BonusSpeakersListAndTitle = styled(SpeakersListAndTitle)`
  margin-top: ${({ theme }) => theme.sizes.lvl4};
  ${({ theme }) => theme.breakpoint('desktop')`
    margin-top: 0;
  `}
`;

const AgendaDayExpertsSection = (day) => {
  let data = day.day.airtable.data;
  let bonusSpeakers = data.Session.map(({ data: sessionData }) => sessionData)
    .filter(({ BonusSession }) => BonusSession)
    .sort((sessionA, sessionB) => sessionA.DateOrder - sessionB.DateOrder)
    .map((data) => ({
      id: data.Speaker[0].id,
      name: data.Speaker[0].data.Name,
      tagline: data.Name,
      image: data.Speaker[0].data.Avatar.localFiles[0],
      websiteUri: data.Speaker[0].data.WebsiteUri,
      slug: data.Slug,
      content: data.Content,
      category: data.Category,
    }));

  let normalSpeakers = data.Session.map(({ data: sessionData }) => sessionData)
    .filter(({ BonusSession }) => !BonusSession)
    .sort((sessionA, sessionB) => sessionA.DateOrder - sessionB.DateOrder)
    .map((data) => ({
      id: data.Speaker[0].id,
      name: data.Speaker[0].data.Name,
      tagline: data.Name,
      image: data.Speaker[0].data.Avatar.localFiles[0],
      websiteUri: data.Speaker[0].data.WebsiteUri,
      slug: data.Slug,
      content: data.Content,
      category: data.Category,
    }));

  let index = parseInt(data.Name.replace('Day ', '')) - 1;

  let date = data.Date;

  return (
    <>
      <ExpertsBackground key={`experts_${data.Slug}`}>
        <Container>
          <SpeakersListAndTitle
            title={data.ShortName}
            description={data.LongName}
            day={index + 1}
            speakers={normalSpeakers}
            colourLevel={dayMeta[data.Date].colourLevel}
            date={date}
          />
          {bonusSpeakers && bonusSpeakers.length > 0 && (
            <BonusSpeakersListAndTitle
              title="Bonus Sessions"
              speakers={bonusSpeakers}
              colourLevel={dayMeta[data.Date].colourLevel}
              date={date}
              hideWebsiteButton
            />
          )}
        </Container>
      </ExpertsBackground>
    </>
  );
};

AgendaDayExpertsSection.propTypes = {
  day: PropTypes.object,
};

AgendaDayExpertsSection.defaultProps = {
  day: null,
};

export default AgendaDayExpertsSection;
